import React, { useEffect, useState } from 'react'
import './HomePage.css'
import { DESIGN_DEVELOPMENT, FEATURE_SLIDE1, FEATURE_SLIDE2, FUNNEL_VIDEO, INITIAL_PLANNING, LAUNCH_SUPPORT, ONEXR_BG_VIDEO, PORTFOLIO_IMAGE1, PORTFOLIO_IMAGE2, PORTFOLIO_IMAGE3, VECTOR_IMAGE1, VECTOR_IMAGE2, WALK_THROUGH_CONTENT_IMG1, WALK_THROUGH_CONTENT_IMG2, WALK_THROUGH_CONTENT_IMG3 } from '../assets/allAssets'
import { Col, Form, Row } from 'react-bootstrap'
import Iframe from 'react-iframe';
import AOS from "aos";
import "aos/dist/aos.css";
import RequestDemoForm from './RequestDemoForm';
import { Quote } from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form';

import Slider from 'react-slick';
import SelectPlanForm from './SelectPlanForm';
import { Spinner } from '../Spinner';


const HomePage = ({ loader, setLoader }) => {

    useEffect(() => {
        AOS.init({
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);

    const [loading, setLoading] = useState(false)
    const [requestFormShow, setRequestFormShow] = React.useState(false);
    const [selectplantFormShow, setSelectplanFormShow] = React.useState(false);
    const [basePlan, setBasePlan] = React.useState('')
    const [pricePlan, setPricePlan] = React.useState('')

    const modalClose = () => {
        setRequestFormShow(false)
        setSelectplanFormShow(false)
        setBasePlan('')
        setPricePlan('')
    }


    const portfolioData = [
        { id: 1, category: 'Small', image: PORTFOLIO_IMAGE1 },
        { id: 2, category: 'Medium', image: PORTFOLIO_IMAGE2 },
        { id: 3, category: 'Large', image: PORTFOLIO_IMAGE3 },
        { id: 4, category: 'Small', image: PORTFOLIO_IMAGE3 },
        // Add more items as needed
    ];

    const [activeFilter, setActiveFilter] = useState('All');

    const filteredPortfolio = activeFilter === 'All'
        ? portfolioData
        : portfolioData.filter(item => item.category === activeFilter);


    // ----- contact form --------
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        console.log(data);
    };

    // -------- portfolio slider ---------
    const settings = {
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    // -------- pricing toggle --------
    const [selectedPlan, setSelectedPlan] = useState('Monthly Subscription');
    const handlePricingToggleChange = (event) => {
        setSelectedPlan(event.target.value);
    };



    return (
        <>
            {/* -- hero page -- */}
            <div style={{ display: loader ? 'block' : 'none' }}>
                <section className='heropage' id='heroSection'>
                    <div className='landingPage'>
                        <div className="video-background">
                            <video preload="auto" src={ONEXR_BG_VIDEO} autoPlay muted loop playsInline onLoadedData={() => setLoader(true)} />
                        </div>
                        <div className='landingpage_content centerDiv f_col'>
                            <h1>Redefine your showroom</h1>
                            <button className='primaryBtn my-4'>Experience the Future</button>
                        </div>
                    </div>
                </section>

                {/* -- walkThroughPage -- */}
                <section className='walkThroughPage' id='walkThroughSection'>
                    <div className='walkThroughPage_Backdrop'>
                        <div className='walkThrough_header container pt-3'>
                            <h5>Boost Your Conversion Rate</h5>
                            <h4 style={{ padding: '0 15%' }}>Engage your audience with an immersive digital experience and an interactive walkthrough of your products and facilities.</h4>
                        </div>
                        <div className='walkThrough_content container'>
                            <Row>
                                <Col md={4}>
                                    <div className='walkThrough_content_div'>
                                        <img src={WALK_THROUGH_CONTENT_IMG1} data-aos="zoom-out" data-aos-delay="300" alt="Image" />
                                        <h5>Enhance Website Engagement & Brand Recall</h5>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='walkThrough_content_div'>
                                        <img src={WALK_THROUGH_CONTENT_IMG2} data-aos="zoom-out" data-aos-delay="600" alt="Image" />
                                        <h5>Showcase Technological Expertise</h5>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='walkThrough_content_div'>
                                        <img src={WALK_THROUGH_CONTENT_IMG3} data-aos="zoom-out" data-aos-delay="900" alt="Image" />
                                        <h5>Strengthen Supplier Credibility</h5>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>

                {/* -- feature -- */}
                <section className='featurePage' id='featureSection'>
                    <div className="feature_slideContent container">
                        <div className='text-center'>
                            <h2>Feature</h2>
                            <p className='feature_slideContent_p'>Your website is often the first impression your customers have of your business during their due diligence process, just as you carefully assess your suppliers. Is your website a true reflection of your brand today, or is it time for an upgrade?</p>
                        </div>
                        <Row className='my-4 py-4'>
                            <Col md={6} className='centerDiv'>
                                <div className='feature_slide_ques'>
                                    <h3 className='mb-4'>Why Does It Matters?</h3>
                                    <p>Your website is often the first stop for potential clients doing their homework. Is it up to date? A polished, engaging online presence can be the deciding factor in winning that RFQ or RFP. Make it count.</p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='centerDiv' data-aos="zoom-in-left">
                                    <img src={FEATURE_SLIDE1} alt="Image" />
                                </div>
                            </Col>
                        </Row>
                        <Row className='my-4 py-4'>
                            <Col md={6}>
                                <div className='centerDiv' data-aos="zoom-in-right">
                                    <img src={FEATURE_SLIDE2} alt="Image" />
                                </div>
                            </Col>
                            <Col md={6} className='centerDiv'>
                                <div className='feature_slide_ques'>
                                    <h3 className='mb-4'>Our Value to You?</h3>
                                    <p>In today’s digital landscape, your website is more than a touchpoint—it’s a testament to your brand’s innovation. We elevate your online presence from static displays to immersive 3D experiences that captivate and build trust. As the industry shifts towards digital sophistication, let your website be a bold reflection of your forward-thinking approach.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                {/* -- portfolio -- */}
                <section className='portfolio_Page' id='portfolioSection'>
                    <div>
                        <h4>Portfolio</h4>
                        <h3>Creative Vision Realized</h3>
                        <p>Transforming standard websites into captivating, virtual experiences that redefine digital engagement.</p>
                        <div className='container my-4'>
                            <div className="slider-container">
                                <Slider {...settings}>
                                    {filteredPortfolio.map((item, index) => (
                                        <>
                                            <div key={item.id} className='centerDiv my-2 portfolio_item'>
                                                <div className='portfolio_link' data-aos="zoom-in" data-aos-delay={200 * (index + 1)}>
                                                    <a href="#" target='_blank'>
                                                        <img src={item.image} alt="Image" />
                                                        <div className='portfolio_link_overlay'>Explore Project</div>
                                                    </a>
                                                </div>
                                            </div>
                                            <label>{item.category}</label>
                                        </>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>

                {/* -- Sevices -- */}
                <section id='sevices'>
                    {/* -- side content -- */}
                    <div className='slideContent'>
                        <div className="feature_slideContent feature_slideContent2 container">
                            <div className='text-center'>
                                <h2 style={{ fontWeight: '800' }}>Sevices</h2>
                                {/* <p className='feature_slideContent_p'>Explore key factors to enhance your website's impact and user experience. Learn about the time commitment, benefits of 3D visuals, and whether a site upgrade is needed.</p> */}
                            </div>
                            <Row className='my-4 py-3 align-items-center'>
                                <Col md={7} className='centerDiv'>
                                    <div className='feature_slide_ques2 right_slide_ques2'>
                                        <h3 className='right_slide_ques2_head mb-4'>Why Choose a 3D Visual Experience?</h3>
                                        <div className='right_slide_ques2_content' data-aos="fade-up" data-aos-delay="500" >
                                            <p><Quote className='quoteStart' /></p>
                                            <ul>
                                                <li>Text-based explanations are often forgotten.</li>
                                                <li>Videos can leave an impression, but still may fade over time.</li>
                                                <li>An interactive 3D experience? It resonates, helping customers truly understand.</li>
                                            </ul>
                                            <p>Stand out from the crowd of traditional content and elevate your brand. Higher engagement means higher conversions.</p>
                                            <p>Simply share your website link and email, and we’ll create a virtual upgrade—free of charge! We’ll show you how your site can look with a 3D transformation, at zero cost to you.<Quote className='quoteEnd' /></p>

                                            {/* <p>Just send your website link and your email, and we will create and send ,an upgraded virtual
                                                website <a href="mailto:website@OneXr.ai?subject=Would%20like%20to%20see%20my%20website%20showroom">for Free!</a></p> */}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <div className='centerDiv'>
                                        <video className='w_100' preload="auto" src={FUNNEL_VIDEO} autoPlay muted playsInline loop data-aos="fade-up" data-aos-delay="500" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='my-4 py-3 align-items-center'>
                                <Col md={5}>
                                    <div className='centerDiv'>
                                        <img src={VECTOR_IMAGE1} alt="Image" data-aos="flip-right" />
                                    </div>
                                </Col>
                                <Col md={7} className='centerDiv'>
                                    <div className='feature_slide_ques2 left_slide_ques2'>
                                        <h3 className='right_slide_ques2_head mb-4'>Quick Turnaround, Lasting Impact</h3>
                                        <div className='right_slide_ques2_content' data-aos="fade-up" data-aos-delay="500" >

                                            <p> <Quote className='quoteStart' />Imagine a fully immersive 3D experience for the cost of a dinner. Yes, it’s that accessible.</p>
                                            <p>With our expertise in digital VR, we deliver polished, interactive experiences within days to weeks—no lengthy wait times or extravagant budgets. Just share your 3D model (Note: Remove all details you do not want to expose), and we’ll transform it into a captivating virtual showcase that resonates with your customers.</p>
                                            <p>Why wait? Let your products speak for themselves in a whole new dimension. <Quote className='quoteEnd' /></p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='my-4 py-3 align-items-center'>
                                <Col md={12} className='centerDiv'>
                                    <div className='feature_slide_ques2 left_slide_ques2'>
                                        <h3 className='right_slide_ques2_head mb-4'>Still not convinced?</h3>
                                        <div className='right_slide_ques2_content' data-aos="fade-up" data-aos-delay="500" >
                                            <p><Quote className='quoteStart' /> You don’t need to provide your phone number—we’ll only call you when you’re ready and have scheduled a time. Simply share your website URL, email address, and name if you'd like, and we’ll get started. Expect your virtual web experience delivered within a few days.</p>
                                            <h5 className='mt-4'>Stand Out in the Digital Marketplace</h5>
                                            <p>In a world where customers have countless supplier options online, it's essential to build confidence in your product, location, or facility. Most visitors spend only a few moments on your website to assess whether you're worth considering. That brief interaction determines if they’ll explore further, send an RFQ or RFP, or finalize their choice.
                                            </p>
                                            <h5 className='mt-4'>How Do You Measure Up Against Competitors?</h5>
                                            <p>To stand out, you need to differentiate in every way possible. If your website doesn’t showcase strong knowledge and build trust, you risk being overlooked and becoming just another logo in a sales funnel.</p>
                                            <h5 className='mt-4'>Engage and Build Trust from the First Click</h5>
                                            <p>When visitors land on your site, you want them to stay, explore, and gain the confidence to contact you. We make it seamless for them to schedule a meeting at their convenience through our integrated calendar tool.</p>
                                            <p>If they’re hesitant to take action, our advanced AI Chatbots initiate meaningful, intelligent conversations. They’re designed to answer questions, address concerns, and gently request contact information once trust is established.</p>
                                            <h5 className='mt-4'>Next-Gen AI Chatbots</h5>
                                            <p>Our AI chatbots go beyond typical automated responses. They engage in natural, human-like conversations about your products and even beyond business topics, helping visitors feel at ease. These bots subtly encourage visitors to share their contact details and schedule a meeting.</p>
                                            <p>Explore our Chatbot here and conveniently schedule a meeting on your calendar.</p>
                                            <h5 className='mt-4'>Secure and Insightful Reporting</h5>
                                            <p>We create and securely host your content on AWS, providing detailed, multidimensional reports to keep you informed every step of the way. <Quote className='quoteEnd' /></p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='my-4 py-3 align-items-center'>
                                <Col md={5}>
                                    <div className='centerDiv'>
                                        <img src={VECTOR_IMAGE2} alt="Image" data-aos="flip-left" />
                                    </div>
                                </Col>
                                <Col md={7} className='centerDiv'>
                                    <div className='feature_slide_ques2 right_slide_ques2'>
                                        <h3 className='right_slide_ques2_head mb-4'>Wondering if You Need to Change Your Website?</h3>
                                        <div className='right_slide_ques2_content'>
                                            <p data-aos="fade-up" data-aos-delay="500" ><Quote className='quoteStart' />No need. Your existing site stays as it is. We simply add a single line of code to link to your new virtual showroom, accessible from an icon or menu option or a portion of your website site of your choice. This will take your website on a virtual tour and allow you to switch back to your home page instantly.<Quote className='quoteEnd' /></p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    {/* -- walkThroughPage 2 content -- */}
                    <div className='walkThroughPage2 pb-5'>
                        {/* <div className='walkThrough_header container'>
                        <h4>Comprehensive Project Lifecycle: Consultation to Launch & Support</h4>
                        <p>This end-to-end process covers every stage of project development, from initial consultation and design to development, optimization, and post-launch support. It begins with discovering the project’s objectives and creating a user-centered design.  Finally, the project is launched and monitored, with continuous support provided to ensure long-term success, security, and performance improvements.</p>
                    </div> */}
                        <div className='walkThrough_content2 container'>
                            <Row>
                                <Col md={4}>
                                    <div className='walkThrough_content_div'>
                                        <img src={INITIAL_PLANNING} alt="Image" data-aos="fade-down" data-aos-delay="600" />
                                        <h5>Initial Consultation</h5>
                                        <p>We begin by understanding your business goals to create a custom web showroom tailored to your needs.</p>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='walkThrough_content_div'>
                                        <img src={DESIGN_DEVELOPMENT} alt="Image" data-aos="fade-down" data-aos-delay="900" />
                                        <h5>Design, Development & Optimization</h5>
                                        <p>Our experts design a visually compelling, functional showroom that aligns with your brand identity.</p>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='walkThrough_content_div'>
                                        <img src={LAUNCH_SUPPORT} alt="Image" data-aos="fade-down" data-aos-delay="1200" />
                                        <h5>Launch & Support</h5>
                                        <p>After launch, we link your showroom to your site and provide ongoing support to captivate customers and drive sales.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>

                {/* -- Blog page -- */}
                <section className='blogPage' id='blogSection'>
                    <div className='walkThrough_header2'>
                        <h3>Crafting immersive web showrooms uniquely tailored to your brand and audience.</h3>
                        <a href="https://calendly.com/admin-onexr/webshowroom" target='_blank' style={{ textDecoration: 'none', color: 'unset' }}>
                            <button className='primaryBtn mt-5'
                                style={{ backgroundColor: '#ff0808' }}
                            >Explore Our Solutions</button></a>
                    </div>
                </section>

                {/* -- Pricing page -- */}
                <section className='pricingPage py-5' id='pricingSection'>
                    <div className='pricing_header container text-center'>
                        <h5>Choose The Best One</h5>
                        <h1 className='mb-5'>Our Packages</h1>
                    </div>
                    <div className="switches-container mb-5">
                        <input
                            type="radio"
                            id="switchMonthly"
                            name="switchPlan"
                            value="Monthly Subscription"
                            checked={selectedPlan === 'Monthly Subscription'}
                            onChange={handlePricingToggleChange}
                        />
                        <input
                            type="radio"
                            id="switchAddon"
                            name="switchPlan"
                            value="One Time Add-ons"
                            checked={selectedPlan === 'One Time Add-ons'}
                            onChange={handlePricingToggleChange}
                        />
                        <label for="switchMonthly">Monthly Subscription</label>
                        <label for="switchAddon">One Time Add-ons</label>
                        <div className="switch-wrapper">
                            <div className="switch">
                                <div>Monthly Subscription</div>
                                <div>One Time Add-ons</div>
                            </div>
                        </div>
                    </div>
                    <div className='pricing_content container'>
                        <Row>
                            <Col md={4}>
                                <div className='pricing_content_div' data-aos="zoom-out-down" data-aos-delay="300">
                                    <div className="pricingHead text-center py-3">
                                        <h4>Basic Plan</h4>
                                        <p>If you are a small business and you are interested in small rebranding then this is a perfect plan for you</p>
                                    </div>

                                    <div>
                                        {selectedPlan === 'Monthly Subscription' ? (
                                            <h1 className='priceAmount py-3'>$49 <span>/month</span></h1>
                                        ) : (
                                            <h1 className='priceAmount py-3' style={{ lineHeight: '.9' }}>$150 <span>one time </span> <br></br>+<br></br> $10 <span>/month</span></h1>
                                        )}
                                        <ul className="top20">
                                            <li><span>Designing a small brand</span></li>
                                            <li><span>Redesign the company logo</span></li>
                                            <li><span>New visual design of the website</span></li>
                                            <li><span>Deploying a website</span></li>
                                            <li className="not-support"><span>Support and care</span></li>
                                        </ul>
                                    </div>
                                    <div className='centerDiv'>
                                        <button className='primaryBtn priceBtn' onClick={() => {setBasePlan(selectedPlan); setPricePlan('Basic Plan'); setSelectplanFormShow(true)}}>Choose plan</button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='pricing_content_div' data-aos="zoom-in" data-aos-delay="900">
                                    <div className="pricingHead text-center py-3">
                                        <h4>Business Plan</h4>
                                        <p>If you are a small business and you are interested in small rebranding then this is a perfect plan for you</p>
                                    </div>

                                    <div>
                                        {selectedPlan === 'Monthly Subscription' ? (
                                            <h1 className='priceAmount py-3'>$99 <span>/month</span></h1>
                                        ) : (
                                            <h1 className='priceAmount py-3' style={{ lineHeight: '.9' }}>$300 <span>one time </span> <br></br>+<br></br> $30 <span>/month</span></h1>
                                        )}
                                        <ul className="top20">
                                            <li><span>Designing a small brand</span></li>
                                            <li><span>Redesign the company logo</span></li>
                                            <li><span>New visual design of the website</span></li>
                                            <li><span>Deploying a website</span></li>
                                            <li className="not-support"><span>Support and care</span></li>
                                        </ul>
                                    </div>
                                    <div className='centerDiv'>
                                        <button className='primaryBtn priceBtn' onClick={() => {setBasePlan(selectedPlan); setPricePlan('Business Plan'); setSelectplanFormShow(true)}}>Choose plan</button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='pricing_content_div' data-aos="zoom-out-down" data-aos-delay="600">
                                    <div className="pricingHead text-center py-3">
                                        <h4>Enterprise Plan</h4>
                                        <p>If you are a small business and you are interested in small rebranding then this is a perfect plan for you</p>
                                    </div>
                                    <div>
                                        {selectedPlan === 'Monthly Subscription' ? (
                                            <h1 className='priceAmount py-3'>$199 <span>/month</span></h1>
                                        ) : (
                                            <h1 className='priceAmount py-3' style={{ lineHeight: '.9' }}>$700 <span>one time, including photography </span> <br></br>+<br></br> $50 <span>/month</span></h1>
                                        )}
                                        <ul className="top20">
                                            <li><span>Designing a small brand</span></li>
                                            <li><span>Redesign the company logo</span></li>
                                            <li><span>New visual design of the website</span></li>
                                            <li><span>Deploying a website</span></li>
                                            <li><span>Studio and product photography</span></li>
                                        </ul>
                                    </div>
                                    <div className='centerDiv'>
                                        <button className='primaryBtn priceBtn' onClick={() => {setBasePlan(selectedPlan); setPricePlan('Enterprise Plan'); setSelectplanFormShow(true)}}>Choose plan</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                {/* -- Contact page -- */}
                <section className='contactPage' id='contactSection'>
                    <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', minHeight: '80vh' }} className='centerDiv'>
                        <div className="container-fluid p-0 h-100">
                            <Row className='h-100'>
                                <Col md={3}></Col>
                                <Col md={6}>
                                    <div className='contact_header container text-center py-4 mt-4'>
                                        <h5>Lets Get In Touch</h5>
                                        <h1 className='mb-2'>Contact OneXR</h1>
                                    </div>
                                    <Form className='contactForm mb-4' data-aos="flip-down" data-aos-delay="300" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="contactFormInput_container">
                                            <Form.Group className="contactFormInput mb-3" controlId="formBasicEmail">
                                                <Form.Label>Name :</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Name"
                                                    {...register('name', { required: 'Name is required' })}
                                                />
                                                {errors.name && <p className='formError_text'>{errors.name.message}</p>}
                                            </Form.Group>
                                            <Form.Group className="contactFormInput mb-3" controlId="formBasicEmail">
                                                <Form.Label>Company Name :</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Company Name"
                                                    {...register('companyName', { required: 'Company Name is required' })}
                                                />
                                                {errors.companyName && <p className='formError_text'>{errors.companyName.message}</p>}
                                            </Form.Group>
                                            <Form.Group className="contactFormInput mb-3" controlId="formBasicEmail">
                                                <Form.Label>Email :</Form.Label>
                                                <Form.Control type="email" placeholder="Enter email"
                                                    {...register('email', { required: 'Email is required', validate: (value) => { if (!/^\S+@\S+\.\S+$/.test(value)) { return 'Invalid email address'; } }, })}
                                                />
                                                {errors.email && <p className='formError_text'>{errors.email.message}</p>}
                                            </Form.Group>

                                            <Form.Group className="contactFormInput mb-3" controlId="formBasicPassword">
                                                <Form.Label>Phone :</Form.Label>
                                                <Form.Control type="number" placeholder="Enter Phone Number"
                                                    {...register('phone', { required: 'Phone number is required', minLength: { value: 7, message: 'Phone number must be at least 7 digits', }, maxLength: { value: 15, message: 'Phone number cannot exceed 15 digits', }, pattern: { value: /^\d{7,15}$/, message: 'Invalid phone number', }, })}
                                                />
                                                {errors.phone && <p className='formError_text'>{errors.phone.message}</p>}
                                            </Form.Group>
                                            <Form.Group className="contactFormInput mb-3 w-100" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Message</Form.Label>
                                                <Form.Control as="textarea" rows={6} {...register('message', { required: 'Message is required' })} />
                                                {errors.message && <p className='formError_text'>{errors.message.message}</p>}
                                            </Form.Group>
                                        </div>
                                        <div className='centerDiv'>
                                            <button className="primaryBtn" style={{ width: '60%', backgroundColor: '#ff0303' }} type="submit">
                                                {loading ? <Spinner /> : 'Submit'}
                                            </button>
                                        </div>
                                    </Form>
                                </Col>
                                <Col md={3}></Col>
                            </Row>
                        </div>
                    </div>
                </section>


                <RequestDemoForm open={requestFormShow} popClose={modalClose} />
                <SelectPlanForm open={selectplantFormShow} popClose={modalClose} base={basePlan} plan={pricePlan} />
            </div>

        </>
    )
}

export default HomePage